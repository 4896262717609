import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import ApplyHero from '../components/ApplyHero'
import { ThreeGrid } from '../components/Grid'
import { UsecaseHeroTriangle } from '../components/Triangle'
import Card from '../components/Card'
import ApiCard from '../components/ApiCard'
import CtaSection from '../components/CtaSection'
import ApplyForm from '../components/ApplyForm'
import scrollToRef from '../utils/scrollToRef'

const ResellerPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const hero = frontmatter.hero
  const advantagesSection = frontmatter.advantagesSection
  const idealSection = frontmatter.idealSection
  const applyRef = React.createRef();
  const applyNav = () => {
    scrollToRef(applyRef);
  }
  return (
    <Layout>
      <SEO 
        title={frontmatter.seoTitle}
        description={frontmatter.seoDescription}
      />
      <ApplyHero data={hero} apllyCallBack={applyNav}/>
      <UsecaseHeroTriangle />
      <section className="section">
        <div className="container">
          <h2 className="section-title is-primary-text has-text-centered">
            {advantagesSection.title}
          </h2>
          <ThreeGrid>
            {advantagesSection.list.map(item=>{
              return(
                <ApiCard cardData={item} boxShadow="none" />
              )
            })}
          </ThreeGrid>
        </div>
      </section>
      <section className="section is-black">
        <div className="container">
          <h2 className="section-title is-white-text has-text-centered">
            {idealSection.title}
          </h2>
          <ThreeGrid>
            {idealSection.list.map(item=>{
              return(
                <Card cardData={item} />
              )
            })}
          </ThreeGrid>
        </div>
      </section>
      <section className="section" ref={applyRef}>
        <div className="container">
          <h2 className="section-title is-primary-text-text has-text-centered">
            Apply now
          </h2>
          <ApplyForm />
        </div>
      </section>
      <CtaSection bgClassName="is-gray-bg" />
    </Layout>
  )
}

ResellerPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ResellerPage

export const pageQuery = graphql`
  query ResellerPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "reseller-page" } }) {
      frontmatter {
        seoTitle
        seoDescription
        hero {
          title
          subtitle
          description
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxHeight: 534, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
        }
        advantagesSection {
          title
          list {
            item
            description
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 37, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                  presentationWidth
                }
              }
            }
          }
        }
        idealSection {
          title
          list {
            item
            description
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 37, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
  }
`
