import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import NonStretchedImage from './NonStretchedImage'
import { cleanPath } from '../utils/path'

const CardWrap = styled.div`
	width: 100%;
	height: 100%;
	padding: 30px 33px 35px;

	border-radius: 10px;
	box-shadow: ${props=>props.boxShadow};
	background: rgba(255, 255, 255, 0.2);
	&:hover {
		cursor: pointer;
		box-shadow: 0 7px 19px rgba(26, 36, 49, 0.2);
	}
	& h5 {
		margin: 15px 0;
		color: 0F2939;
		font-size: 18px;
	}
	& p {
		color: #000000;
		font-size: 16px;
	}
	
`
CardWrap.defaultProps = {
	boxShadow: '0 2px 8px rgba(26, 36, 49, 0.2)'
}
const CardImage = styled.div`
  position: relative;
	height: 40px;
	.gatsby-image-wrapper {
		max-width: 100px;
		max-height: 60px;
	}
	img {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
`
const ApiCard = ({ cardData, boxShadow }) => {
	return(
	<Link to={cleanPath(cardData.link)}>
		<CardWrap boxShadow={boxShadow}>
			<CardImage>
				<NonStretchedImage
					objectFit="contain"
					alt={cardData.item}
					className=""
					{...cardData.image}
	      />
			</CardImage>
			<h5>{cardData.item}</h5>
			<p>{cardData.description}</p>
		</CardWrap>
	</Link>
	)
}

export default ApiCard