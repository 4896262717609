import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import NonStretchedImage from './NonStretchedImage'
import { TwoGrid } from './Grid'
import { cleanPath } from '../utils/path'

const HeroWrap = styled.div`
	position: relative;
	width: 100%;
	padding: 130px 0 50px;
	background: #F6F9F9;
	& label {
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;
		color: #6F8D9E;
	}
	& h1 {
		width: 100%;
		max-width: 800px;
		margin: 10px auto;
		margin-left: -5px;
		color: #122D3D;
	}
	& p {
		width: 100%;
		max-width: 558px;
		margin: 10px auto 20px;
		color: #333333;
		font-size: 18px;
	}
	@media(max-width: 1024px){
		& h1 {
			font-size: 40px;
		}
	}
	@media(max-width: 768px){
		padding: 80px 0 30px;
		& h1 {
			margin-left: 0px;
		}
		& p {
			margin-left: 0;
		}
	}
`
const ButtonWrap = styled.div`
	position: relative;
	display: flex;
	max-width: 400px;
	z-index: 20;
	margin: 20px 0 37px;
	& a {
		width: 48%;
		&:nth-child(1) {
			margin-right: 3%;
		}
	}
	@media(max-width: 600px) {
		display: block;
		margin: 0;
	}
`
const Button = styled.button`
	width: fit-content;
	max-width: 300px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-top: 20px;
  padding: 12px;
  &:focus {
    outline: none !important;
  }
  &:hover {
  	background: #6F8D9E;
  	border: 1px solid #6F8D9E;
    cursor: pointer;
  }
`
const ImageWrap = styled.div`
	position: relative;
	.gatsby-image-wrapper {
		width: 100%;
		margin: auto;
	}
	@media(max-width: 768px){
		.gatsby-image-wrapper {
			max-width: 500px;
			width: 100%;
			margin: auto;
			margin-bottom: -60px;
		}
	}
`

class ApplyHero extends React.Component {
	constructor(props) {
		super(props)
		this.applyClicked = this.applyClicked.bind(this)
	}
	applyClicked() {
		const { apllyCallBack } = this.props
		if(apllyCallBack) {
			apllyCallBack();
		}
	}
	render() {
		const { data } = this.props
		return(
			<HeroWrap>
				<section>
					<div className="container">
						<TwoGrid>
							<div>
								<label>{data.subtitle}</label>
								<h1>{data.title}</h1>
								<p>{data.description}</p>
								<ButtonWrap>
									<Button 
										className="is-primary" 
										onClick={this.applyClicked}
									>
										Apply now
									</Button>
								</ButtonWrap>
							</div>
							<div>
								<ImageWrap>
									<div>
										<NonStretchedImage
											objectFit="contain"
											alt="hero-image"
											className=""
											{...data.image}
							      />
									</div>
								</ImageWrap>
							</div>
						</TwoGrid>
					</div>
				</section>
			</HeroWrap>
		)
	}
}

export default ApplyHero