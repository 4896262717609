import React from 'react'
import styled from 'styled-components'
import NonStretchedImage from './NonStretchedImage'

const CardWrap = styled.div`
	width: 100%;
	height: 100%;
	padding: 31px 19px 35px;

	border-radius: 10px;
	background: rgba(255, 255, 255, 0.2);
	& h5 {
		margin: 15px 0;
	}
	
`
const CardImage = styled.div`
  position: relative;
	height: 40px;
	.gatsby-image-wrapper {
		max-width: 100px;
		max-height: 60px;
	}
	img {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
`
const Card = ({ cardData }) => {
	return(
		<CardWrap>
			<CardImage>
				<NonStretchedImage
					objectFit="contain"
					alt={cardData.item}
					className=""
					{...cardData.image}
	      />
			</CardImage>
			<h5 className="is-white-text">{cardData.item}</h5>
			{cardData.description?(<p className="is-white-text">{cardData.description}</p>):(<></>)}
		</CardWrap>
	)
}

export default Card